import React from "react";
import PopUp from "../Common/PopUp";
import CouponBox from "../Common/CouponBox";

const CouponCodeModal = (props) => {
	const [couponCode, SetCouponCode] = React.useState("");

	const OnChange = (e) => {
		const code = e.currentTarget.value;
		SetCouponCode(code);
	};


	return (
		<PopUp title={props.coupons.length > 0 ? "Kupon Kodu Kullan" : "Aktif Kupon Yok"} Hide={props.Hide}>
			<div className={props.isMobile ? "grid-1" : "grid-2"}>
				{props.coupons.length > 0 ? (
					props.coupons.map((coupon, index) => (
						<div key={index}>
						<CouponBox
							code={coupon.code}
							bonus={coupon.amount}
							category_name={coupon.name}
							coupon_limit={coupon.min_amount}
							coupon_last_date={coupon.end_datetime}
							UseCouponCode={props.UseCouponCode}
						/>
						</div>
					))
				) 
				: 
				(<>Kupon kodunuz bulunmamaktadır.</>)
				}
			</div>
		</PopUp>
	);
};

export default CouponCodeModal;