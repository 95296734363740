import React from "react";
import MyCouponsCore from "./MyCouponsCore";
import CouponBox from "../../Common/CouponBox";

class DesktopRenderer extends MyCouponsCore {
	render() {
		const { coupons } = this.state;
		return (
      <div className="settings-box">
        <div className="grid-2">
          <div className="rewardCoupon max nonPointer">
            <div className="couponValue">
              <div className="value">???</div>
              <div className="desc">İNDİRİM</div>
            </div>
            <div className="couponDesc">
              <div>
                <input 
                  type="text" 
                  className="couponAddInput form-control" 
                  placeholder="Kupon Kodunuzu Yazın..."  
                  onChange={(e) => this.setState({ new_coupon: e.target.value })}
                /> 
              </div>
              <div className="addCouponBtn"><span onClick={() => this.AddCoupon(this.state.new_coupon)}>+ İndirim Kuponunu Ekle</span></div>
            </div>
          </div>
          {coupons.map((coupon, index) => (
            <CouponBox 
              key={index} 
              bonus={coupon.amount}
              category_name={coupon.name}
              coupon_limit={coupon.min_amount}
              coupon_last_date={coupon.end_datetime}
              code={coupon.code}
              slug={coupon.slug ? coupon.slug : "#"}
              position={"profile"}
            />
          ))}
        </div>
      </div>
		);
	}
}

export default DesktopRenderer;
