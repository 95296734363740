import React from "react";
import MyCartCore from "./MyCartCore";
import Currency from "../../packs_helper/scripts/Currency";
import MyCartProduct from "./MyCartProduct";
import CouponCodeModal from "./CouponCodeModal";
import BuyNowPopup from "./BuyNowPopup";
import PopUp from "../Common/PopUp";

class DesktopRenderer extends MyCartCore {
	render() {
		return (
			<div className="my-cart-container">
				<div className="my-cart-wrapper">
					{this.state.items.map((item) => (
						<MyCartProduct
							item={item}
							UpdateItem={this.UpdateItem}
							key={item.product_id}
							PayCart={this.PayCart}
						/>
					))}
				</div>
				<div className="my-cart-right">
					<div className="my-cart-right-summary">
						{this.state.items.map((item) => {
							return (
								<React.Fragment key={item.product_id}>
									<div className="my-cart-right-summary-item">
										<div className="my-cart-right-summary-item-name">{item.name}</div>
										{item.quantity > 1 ? ` x${item.quantity}` : ""}:
									</div>
									<strong>{Currency(item.product_cost)} ₺</strong>
								</React.Fragment>
							);
						})}
						{this.state.discount > 0 && (
							<React.Fragment>
								<div>Ürün İndirimi:</div>
								<strong>{Currency(-1 * this.state.discount)} ₺</strong>
							</React.Fragment>
						)}
						{this.state.coupon_discount > 0 && (
							<React.Fragment>
								<div>Kupon İndirimi: </div>
								<strong>
									{Currency(
										-1 * this.state.coupon_discount
									)}{" "}
									₺
								</strong>
							</React.Fragment>	
						)}
						<hr />
						<div className="my-cart-right-summary-total">Ödenecek Tutar:</div>
						<strong className="my-cart-right-summary-total">
							{Currency(this.state.total)} ₺
						</strong>
					</div>
					<div className="my-cart-right-footer">
						<div className="my-cart-right-footer-info">
							<svg className="icon">
								<use xlinkHref="#gs-icon-my-cart-shield"></use>
							</svg>
							Paranız %100 GameSatış güvencesi altındadır.
						</div>
						{this.props.user.signed_in ? (
							<React.Fragment>
								<button className="btn btn-jungle" onClick={this.PayCart} disabled={this.state.requesting}>Bakiye ile Öde</button>
								{this.state.coupon_discount > 0 ? (
								<button
									className="btn btn-red"
									onClick={this.RemoveCouponCode}
                  disabled={this.state.requesting}
								>
									İndirim Kodunu Sil
								</button>
								) : (
								<button
									className="btn btn-default"
									onClick={this.ToggleCouponCodeModal}
                  disabled={this.state.requesting}
								>
									İndirim Kodu Kullan
								</button>
								)}
							</React.Fragment>
						) : (
							<React.Fragment>
								<button className="btn btn-jungle" onClick={this.ToggleBuyNowPopup} disabled={this.state.requesting}>Hemen Al</button>
								<button className="btn btn-jungle" onClick={this.Login} disabled={this.state.requesting}>
									Üye Girişi
								</button>
							</React.Fragment>
						)}
					</div>
				</div>
				{this.state.couponCodeModalVisible && (
					<CouponCodeModal
						UseCouponCode={this.UseCouponCode}
						Hide={this.ToggleCouponCodeModal}
						coupons={this.state.coupons}
						isMobile={false}
					/>
				)}
				{this.state.buyNowPopupVisible && (
					(this.props.items.findIndex(item => !item.is_other_seller_product) === -1) ? (
						<PopUp
							Hide={this.ToggleBuyNowPopup}
							title="Hızlı al için uygun değil!"
							>
							<div>
								<p>Bu ürün için hemen öde seçeneği mevcut değildir. Ürünü satın alabilmek için kayıt olmanız gerekmektedir.</p>
								<p>Lütfen kayıt olduktan sonra üye girişi yaparak işleminizi tamamlayınız.</p>
								<p>
									<button className="btn btn-jungle" onClick={this.SignUp} disabled={this.state.requesting}>
										Kayıt Ol
									</button>
								</p>
							</div>
						</PopUp>
					) : (
						<BuyNowPopup
							Hide={this.ToggleBuyNowPopup}
						/>
					)
				)}
			</div>
		);
	}
}

export default DesktopRenderer;
