import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";

class MyCommentsCore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coupons: [],
      new_coupon: ""
    };
    this.GetData = this.GetData.bind(this);
    this.AddCoupon = this.AddCoupon.bind(this);
  }

  componentDidMount() {
    this.GetData();
  }

  GetData() {
    fetch("/my-coupons")
      .then(async (response) => {
        const couponsResult = await response.json();
        if(couponsResult.coupons.length){
          this.setState({
            coupons: couponsResult.coupons
          });
        }
      })
      .catch((err) => {

      });
  }

  AddCoupon(code) {
    PostData("/set-coupon", { coupon: code })
      .then((result) => {
        if(result.state === "success"){
          this.GetData();
        }
      });
  }
}

export default MyCommentsCore;
