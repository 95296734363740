import React from "react";
import PostData from "../../packs_helper/scripts/PostData";

class MyCartCore extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			// mounted: false,
			discount: props.discount,
			total: props.total,
			subtotal: props.subtotal,
			coupon_rate: props.coupon_rate,
			coupon_discount: props.coupon_discount,
			items: props.items,

			coupons: [],
			couponCodeModalVisible: false,
			buyNowPopupVisible: false,
      		requesting: false,
		};

		this.UpdateItem = this.UpdateItem.bind(this);
		this.RemoveCouponCode = this.RemoveCouponCode.bind(this);
		this.UseCouponCode = this.UseCouponCode.bind(this);
		this.ToggleCouponCodeModal = this.ToggleCouponCodeModal.bind(this);
		this.ToggleBuyNowPopup = this.ToggleBuyNowPopup.bind(this);
		this.Login = this.Login.bind(this);
		this.SignUp = this.SignUp.bind(this);
		this.PayCart = this.PayCart.bind(this);
	}

	componentDidMount() {
		// this.setState({mounted: true});
		// const [totalCost, totalDiscountCost] = this.props.items.reduce(function(a,b){ return [a[0] + b.product_discount_cost, a[1] + b.product_cost]}, [0,0])
		// this.setState({
		// 	totalCost,
		// 	totalDiscount: totalCost - totalDiscountCost
		// })
	}

	UpdateItem(product_id, value) {
		if (!value.state) {
			return false;
		}

		const items = this.state.items;
		const index = items.findIndex((item) => item.product_id == product_id);
		items[index] = {
			...items[index],
			quantity: value.quantity,
			product_cost: value.product_cost,
			product_discount_cost: value.product_discount_cost,
		};

		const newItems = items.filter(item => !!item.quantity);

		if(newItems.length === 0){
			setTimeout(function(){
				window.location.reload();
			}, 3000);
		}

		this.setState({
			items: newItems,
			discount: value.discount,
			total: value.total,
			subtotal: value.subtotal,
		});
	}

	RemoveCouponCode() {
		this.UseCouponCode('@@@');
	}

	UseCouponCode(coupon_code) {
    this.setState({
      requesting: true
    });
		PostData("/update-cart", {
			coupon_code,
		})
			.then((response) => {
				if (response.state) {
					window.location.reload();
					this.setState({
						coupon_rate: response.coupon_rate,
						coupon_discount: response.coupon_discount,
						discount: response.discount,
						total: response.total,
						subtotal: response.subtotal,
						couponCodeModalVisible: false,
					});
				}
        else{
          this.setState({
            requesting: false
          });
        }
			})
			.catch((err) => {
        this.setState({
          requesting: true
        });
			});
	}

	ToggleCouponCodeModal() {
		fetch("/my-coupons?cart=true")
		.then(async (response) => {
			const couponResponse = await response.json();
			if (response) {
				this.setState((prevState) => ({
					coupons: couponResponse.coupons,
					couponCodeModalVisible: !prevState.couponCodeModalVisible,
				}));
				// this.setState({
				// 	coupon_rate: response.coupon_rate,
				// 	coupon_discount: response.coupon_discount,
				// 	discount: response.discount,
				// 	total: response.total,
				// 	subtotal: response.subtotal,
				// 	couponCodeModalVisible: false,
				// });
			}
			else{
				this.setState({
					requesting: false
				});
			}
		})
		.catch((err) => {
			this.setState({
				requesting: true
			});
		});

		
	}

	ToggleBuyNowPopup() {

		this.setState((prevState) => ({
			buyNowPopupVisible: !prevState.buyNowPopupVisible,
		}));
	}

	Login() {
		window.redirect("/giris-yap");
	}

	SignUp() {
		window.redirect("/kayit-ol");
	}

	PayCart(){
    this.setState({
      requesting: true
    });
		PostData("/alisverisi-tamamla", {
		})
			.then((response) => {
        if(response.state){
          dataLayer.push({
            'event': 'shoppingComplete',
            'response': response.state,
            'transactionId': response.analytics.transactionId,
            'transactionTotal': parseFloat(response.analytics.transactionTotal),
            'transactionProducts': response.analytics.transactionProducts,
			'customerData':[
				  response.user_data.email,
				  response.user_data.phone_number,
				  response.user_data.first_name,
				  response.user_data.last_name,
			  ]
          });
        }
        else{
          this.setState({
            requesting: false
          });
          dataLayer.push({
            'event': 'shoppingComplete',
            'response': false
          });
        }
			})
			.catch((err) => {
        this.setState({
          requesting: false
        });
			});
	}
}


export default MyCartCore;
