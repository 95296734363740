import React from "react";
import Currency from "../../packs_helper/scripts/Currency";

const CouponBox = (props) => {
  const { bonus, category_name, coupon_limit, coupon_last_date, code, position = "cart", slug } = props;
  const [hoverActive, setHoverActive] = React.useState(false);


  return (
    position === "profile" ? 
      <a 
        href={slug}  
        target={slug !== "#" ? "_blank" : "_self"}
        className="rewardCoupon max"
        onMouseEnter={() => setHoverActive(true)}
        onMouseLeave={() => setHoverActive(false)}
      >
        <div className="couponValue">
          <div className="value">{ Currency(bonus) } ₺</div>
          <div className="desc">İNDİRİM</div>
        </div>
        <div className="couponDesc">
          <div>{ category_name }</div>
          <div><span>Alt limit:</span> { Currency(coupon_limit) } ₺</div>
          <div><span>Son Kullanım:</span> { coupon_last_date.split("T")[0].split("-").reverse().join("/") }</div>
        </div>
        {slug !== "#" && <div className={hoverActive ? "useCoupon active" : "useCoupon"}>Kuponu Kullan</div>}
      </a>
      :
      <div className="rewardCoupon" onClick={() => props.UseCouponCode(code)}>
        <div className="couponValue">
          <div className="value">{ Currency(bonus) } ₺</div>
          <div className="desc">İNDİRİM</div>
        </div>
        <div className="couponDesc">
          <div>{ category_name }</div>
          <div><span>Alt limit:</span> { Currency(coupon_limit) } ₺</div>
          <div><span>Son Kullanım:</span> { coupon_last_date.split("T")[0].split("-").reverse().join("/") }</div>
        </div>
      </div>
  );
};

export default CouponBox;