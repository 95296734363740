import React from "react";
import Currency from "../../../packs_helper/scripts/Currency";
import DateFormat from "../../../packs_helper/scripts/DateFormat";
import Paginate from "../../Common/Paginate";
import MyInvoicesCore from "./MyInvoicesCore";

class DesktopRenderer extends MyInvoicesCore {
	render() {
		const { invoices, invoice_count, page } = this.state;
		return (
      <div>
        <div className="my-invoices-container grid-1">
            <div className="invoice-row" >
              Bugün onaylanan işlemlerinizin faturaları dört gün sonra kesilecektir.
            </div>
            {invoices.map((invoice) => (
              <a href={`/fatura?id=${invoice.id}`} target="_blank" key={invoice.id}>
              <div className="invoice-row" >
                <div className="invoice-row-content">
                  {new Date(invoice.date_at).toDateString() !== new Date().toDateString() ? (
                    <div className="status-icon status-icon-jungle"><svg className="icon"><use xlinkHref="#gs-icon-user-panel-approved"></use></svg></div>
                  ): (
                    <div className="status-icon status-icon-orange"><svg className="icon"><use xlinkHref="#gs-icon-user-panel-pending"></use></svg></div>
                  )}
                  <strong>
                    #{invoice.no}
                  </strong>
                  <div>Tarih: <strong className="text-red">{DateFormat(invoice.date_at, '%d/%m/%Y')}</strong></div>
                  <div>Toplam: <strong className="text-green">{Currency(invoice.total)} ₺</strong></div>
                </div>
                <div>
                  <button className="btn"><svg className="icon"><use xlinkHref="#gs-icon-user-panel-caret-down"></use></svg></button>
                </div>
              </div>
              </a>
            ))}
        </div>
        <div className="my-invoices-container footer">
          {invoice_count > 0 ? (
            <div className="notifications-footer">
              <Paginate
                onChange={this.ChangePage}
                total_count={invoice_count}
                current_page={this.state.current_page}
                per_page={15}
              />
            </div>
          ) : (
            <div className="alert">Hiç faturanız bulunmuyor!</div>
          )}
        </div>
      </div>
		);
	}
}

export default DesktopRenderer;
