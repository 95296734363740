import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";
import PopUp from "../../Common/PopUp";

class OrderDetailButtons extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			itemReceivedConfirmationPopup: false,
		};

		this.SendMessage = this.SendMessage.bind(this);
		this.ItemReceivedHandler = this.ItemReceivedHandler.bind(this);
		this.ItemReceived = this.ItemReceived.bind(this);
		this.ItemCancelled = this.ItemCancelled.bind(this);
		this.ShowInvoice = this.ShowInvoice.bind(this);
		this.SendIssue = this.SendIssue.bind(this);
		this.DownloadExcel = this.DownloadExcel.bind(this);

	}


	SendMessage() {
		this.props.ChangeRoute(
			`/mesajlarim?active_tab=${this.props.order_detail.content.user_code}`
		);
	}

	ItemReceived() {
		this.setState({
			itemReceiveSaving: true,
		});

		if(this.props.order_detail.amount < 10000) {
			PostData("/alici-onayi", {
				order_detail_id: this.props.order_detail.id,
			})
			.then((response) => {
				this.GetData(this.state.current_page);
				this.setState({
					itemReceiveSaving: false,
					itemReceivedConfirmationPopup: false,
				});
			})
			.catch((err) => {


				this.setState({
					itemReceiveSaving: false,
					itemReceivedConfirmationPopup: false,
				});
			});
		}
		
	}

	ItemReceivedHandler() {
		this.setState({
			itemReceivedConfirmationPopup: true,
		});
	}

	ItemCancelled() {
		ShowPopup({
			title: "Onay Gerekiyor!",
			message:
				'<p>Ürünün satışını iptal etmek istiyor musunuz?</p><p><i>Satışı iptal ediyorsunuz. Bu işlem geri alınamaz!</i></p><button value="0" class="btn btn-default" data-close>Vazgeç</button><button value="1" class="btn btn-red" data-close>Evet, satışı iptal et</button>',
		}).then((button) => {
			if (button.value == 1) {
				PostData("/alici-onayi", {
					order_detail_id: this.props.order_detail.id,
				})
					.then((response) => {
						this.GetData(this.state.current_page);
					})
					.catch((err) => {


					});
			}
		});
	}

	ShowInvoice() {
		const form = document.createElement("FORM");
		form.action = "/fatura?id=" + this.props.order_detail.id;
		form.target = "_blank";
		form.method = "post";
		form.style.display = "none";

		const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
		const hiddenInput = document.createElement("INPUT");
		hiddenInput.type = "hidden";
		hiddenInput.name = "authenticity_token";
		hiddenInput.value = csrfToken;
		form.appendChild(hiddenInput);

		document.body.appendChild(form);
		form.submit();
	}

	SendIssue() {
		PostData("/sorun-bildir", {
			order_detail_id: this.props.order_detail.id,
		}).then((response) => {
			if (!!response.state) {
				this.props.ChangeRoute(
					`/mesajlarim?active_tab=${this.props.order_detail.content.user_code}`
				);
			}
		});
	}

	DownloadExcel(e) {
		const id = e.currentTarget.dataset.id;

		const form = document.createElement("FORM");
		form.action = "/kodlar.xls?id=" + id;
		form.target = "_blank";
		form.method = "post";
		form.style.display = "none";

		const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
		const hiddenInput = document.createElement("INPUT");
		hiddenInput.type = "hidden";
		hiddenInput.name = "authenticity_token";
		hiddenInput.value = csrfToken;
		form.appendChild(hiddenInput);

		document.body.appendChild(form);
		form.submit();
	}

	render() {

		if (this.props.order_detail.content.type !== "item") {
			return (
				<div className="order-detail-buttons">
					<button
						className="btn btn-sm btn-default fixed-width-button"
						data-id={this.props.order_detail.id}
						onClick={this.DownloadExcel}
						disabled={!this.props.order_detail.content.keys.length}
					>
						Excel İndir
					</button>
				</div>
			);
		} else {
			if (this.props.order_detail.status === "pending") {
				return (
					<div className="order-detail-buttons">
						<button
							className="btn btn-sm btn-blue fixed-width-button"
							onClick={this.SendMessage}
							disabled={!this.props.order_detail.content.user_code || this.props.order_detail.content.store_id === 121534}
						>
							Satıcı ile İletişime Geç
						</button>

						<button
							className="btn btn-sm btn-jungle fixed-width-button"
							onClick={this.ItemReceivedHandler}
							disabled={!this.props.order_detail.content.is_approvable || this.props.order_detail.amount > 10000}
						>
							{this.props.order_detail.amount > 10000 ? "Canlı Destek'e Yazın" : 'Teslim Aldım' }
						</button>
						<button
							className="btn btn-sm btn-red fixed-width-button"
							onClick={this.SendIssue}
							disabled={
								!this.props.order_detail.content.is_approvable ||
								this.props.order_detail.content.report_problem ||
								this.props.order_detail.status != "pending"
							}
						>
							Sorun Bildir
						</button>

						{this.state.itemReceivedConfirmationPopup && (
							<PopUp
							title="Ürünü teslim aldınız mı?"
							Hide={(e) =>
											this.setState({ itemReceivedConfirmationPopup: false })
										}
							>
								<p>
									<i>
										Teslim almadığınız bir ürünü{" "}
										<strong>
											<u>onaylamayınız</u>
										</strong>
										. Size teslim edilmeyen bir ürünü teslim aldığınızı
										belirtirseniz satış işlemine ait ödeme satıcıya aktarılır ve
										bu işlem{" "}
										<strong>
											<u>geri alınamaz</u>
										</strong>
										.
									</i>
								</p>
								<p>
									<i>
										Satıcı size teslim edilmemiş bir siparişi onaylamınızı
										isterse lütfen onaylamadan <u>canlı destek</u> ile iletişime
										geçerek durumu bildiriniz.
									</i>
								</p>

								<p>
									<div className="checkbox">
										<input
											type="checkbox"
											name="confirm1"
											id="confirm1"
											value="1"
											checked={this.state.confirm1}
											onChange={(e) =>
												this.setState({ confirm1: e.target.checked })
											}
										/>
										<label htmlFor="confirm1">
											<div>
												<strong>{this.props.order_detail.name}&nbsp;</strong> adlı
												ürünü eksiksiz ve çalışır durumda teslim aldığımı
												onaylıyorum.
											</div>
										</label>
									</div>
								</p>
								<p>
									<div className="checkbox">
										<input
											type="checkbox"
											name="confirm2"
											id="confirm2"
											value="1"
											checked={this.state.confirm2}
											onChange={(e) =>
												this.setState({ confirm2: e.target.checked })
											}
										/>
										<label htmlFor="confirm2">
											Vermiş olduğum onaydan sonra ürünün ücretinin satıcıya
											aktarılacağını ve ürünün hatalı ya da eksik olmasından
											GameSatış'ın sorumlu olmadığını biliyor ve onaylıyorum.
										</label>
									</div>
								</p>
								<hr />
								<div className="grid-2 popup-buttons">
									<button
										className="btn btn-default"
										onClick={(e) =>
											this.setState({ itemReceivedConfirmationPopup: false })
										}
										disabled={this.state.itemReceiveSaving}
									>
										Hayır, Henüz Teslim Almadım
									</button>
									<button
										className="btn btn-jungle"
										disabled={
											!this.state.confirm1 ||
											!this.state.confirm2 ||
											this.state.itemReceiveSaving
										}
										onClick={this.ItemReceived}
									>
										Evet, Tamamen Teslim Aldım
									</button>
								</div>
							</PopUp>
						)}
					</div>
				);
			} else if (this.props.order_detail.status === "approved") {
				return (
					<div className="order-detail-buttons">
						<button
							className="btn btn-sm btn-blue fixed-width-button"
							onClick={this.SendMessage}
							disabled={!this.props.order_detail.content.user_code || this.props.order_detail.content.store_id === 121534}
						>
							Satıcı ile İletişime Geç
						</button>

						<button
							className="btn btn-sm btn-jungle fixed-width-button"
							disabled={true}
						>
							Teslim Alındı
						</button>

						{this.props.order_detail.content.store_id !== 121534 && (
							<button
								className="btn btn-sm btn-red fixed-width-button"
								disabled={true}
							>
								Sorun Bildir
							</button>
						)}
					</div>
				);
			} else if (this.props.order_detail.status === "rejected") {
				return (
					<div className="order-detail-buttons">
						<button
							className="btn btn-sm btn-blue fixed-width-button"
							onClick={this.SendMessage}
							disabled={!this.props.order_detail.content.user_code || this.props.order_detail.content.store_id === 121534}
						>
							Satıcı ile İletişime Geç
						</button>

						<button
							className="btn btn-sm btn-jungle fixed-width-button"
							disabled={true}
						>
							Teslim Edilmedi
						</button>
						<button
							className="btn btn-sm btn-red fixed-width-button"
							disabled={true}
						>
							İptal Edildi
						</button>
					</div>
				);
			} else {
				return null;
			}
		}
	}
}

export default OrderDetailButtons;
