import React from "react";
import ProfileBoxInput from "../_ProfileBoxInput";
import SettingsBox from "./SettingsBox";

import SelectBox from "../../Common/SelectBox";
import PopUp from "../../Common/PopUp";
import PostData from "../../../packs_helper/scripts/PostData";

const invoiceTypes = ["Bireysel", "Kurumsal"];

class ProfileSettings extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			popup: null,
			invoice_type: "0",
			cities: [],
			towns: [],
			city_id: "",
			town_id: "",
		};

		this.TogglePopup = this.TogglePopup.bind(this);
		this.SaveAddress = this.SaveAddress.bind(this);
		this.GetCities = this.GetCities.bind(this);
		this.GetTowns = this.GetTowns.bind(this);
		this.ChangeCity = this.ChangeCity.bind(this);
		this.ChangeTown = this.ChangeTown.bind(this);
		this.ChangeInvoiceType = this.ChangeInvoiceType.bind(this);
	}

	componentDidUpdate(prevProps) {

		if (
			this.props.city_id !== prevProps.city_id ||
			this.props.town_id !== prevProps.town_id ||
			this.props.invoice_type !== prevProps.invoice_type
		) {
			this.setState({
				city_id: this.props.city_id,
				town_id: this.props.town_id,
				invoice_type: this.props.invoice_type + "",
			});
		}
	}

	TogglePopup(e) {
		const popup = e.currentTarget.value;

		if (popup === "change-address") {
			this.GetCities();
			if (this.state.city_id) {
				this.GetTowns(this.state.city_id);
			}
		}
		this.setState({
			popup,
		});
	}

	ChangeInvoiceType(invoice_type) {
		this.setState({
			invoice_type,
		});


	}

	ChangeCity(city_id) {
		this.setState({
			city_id,
			town_id: "",
		});
		this.GetTowns(city_id);
	}

	ChangeTown(town_id) {
		this.setState({
			town_id,
		});
	}

	GetCities() {
		PostData("/get_cities")
			.then((response) => {
				this.setState({
					cities: response,
				});
			})
			.catch((err) => {


			});
	}

	GetTowns(city_id) {
		PostData("/get_towns", { city_id })
			.then((response) => {
				this.setState({
					towns: response,
				});
			})
			.catch((err) => {


			});
	}

	SaveAddress(e) {
		e.preventDefault();
		const formData = new FormData(e.target);

		PostData("/adres-kaydet", formData)
			.then((response) => {
				if (response.state) {
					this.props.UpdateData();
					this.setState({ popup: null });


				}
			})
			.catch((err) => {


			});
	}

	render() {
		return (
			<SettingsBox
			icon="invoice"
			title="Fatura Bilgileri"
			id="invoice-settings"
			show={this.props.activeBox === "invoice-settings"}
			BoxOpen={this.props.BoxOpen}
			>
				<div className="profile-settings">
					<div className="settings-input">
						<label>Fatura Türü:</label>
						<div className="settings-input-text">
							{invoiceTypes[this.props.invoice_type]}
						</div>
					</div>
					{this.props.invoice_type === 0 ? (
						<div className="settings-input">
							<label>Ad Soyad:</label>
							{this.props.not_show_identification_number ? (
								<div className="settings-input-text">
									{this.props.name[0]}***** {this.props.lastname[0]}******
								</div>
							) : (
								<div className="settings-input-text">
									{this.props.name} {this.props.lastname}
								</div>
							)}
						</div>
					) : (
						<React.Fragment>
							<div className="settings-input">
								<label>Firma Adı:</label>
								{this.props.not_show_identification_number ? (
									<div className="settings-input-text">******</div>
								) : (
									<div className="settings-input-text">
										{this.props.cafe_name}
									</div>
								)}
							</div>
							<div className="settings-input">
								<label>Vergi Dairesi:</label>
								{this.props.not_show_identification_number ? (
									<div className="settings-input-text">******</div>
								) : (
									<div className="settings-input-text">
										{this.props.tax_office}
									</div>
								)}
							</div>
							<div className="settings-input">
								<label>Vergi No:</label>
								{this.props.not_show_identification_number ? (
									<div className="settings-input-text">******</div>
								) : (
									<div className="settings-input-text">
										{this.props.tax_number}
									</div>
								)}
							</div>
						</React.Fragment>
					)}

					<div className="settings-input">
						<label>Adres:</label>
						{this.props.not_show_identification_number ? (
							<div className="settings-input-text">******</div>
						) : (
							<div className="settings-input-text">{this.props.address}</div>
						)}
					</div>
					<button
						className="btn btn-profile"
						onClick={this.TogglePopup}
						value="change-address"
					>
						FATURA BİLGİLERİNİ GÜNCELLE
					</button>
				</div>

				{this.state.popup === "change-address" && (
					<PopUp
						title="Fatura Bilgilerini Düzenle"
						Hide={() => this.setState({ popup: null })}
					>
						<form
							action="#"
							onSubmit={this.SaveAddress}
							className="grid-1"
							autoComplete="off"
							style={{ width: 400 }}
						>
							<div className="input-with-label">
								<label>Fatura Türü:</label>
								<SelectBox
									xlinkHref="#gs-icon-user-panel-caret-down"
									options={invoiceTypes.map((x, index) => ({
										id: index,
										name: x,
									}))}
									onChange={this.ChangeInvoiceType}
									filter={true}
									selected={this.state.invoice_type}
									name="invoice_type"
									noDark={true}
								/>
							</div>
							{this.state.invoice_type == 0 ? (
								<div className="input-with-label">
									<label>Ad Soyad:</label>
									<div className="input">
										{this.props.name + " " + this.props.lastname}
									</div>
								</div>
							) : (
								<React.Fragment>
									<div className="input-with-label">
										<label>Firma Adı:</label>
										<input
											type="text"
											value={this.props.cafe_name}
											onChange={(e) =>
												this.props.UpdateData({
													cafe_name: e.target.value,
												})
											}
											name="cafe_name"
										/>
									</div>
									<div className="input-with-label">
										<label>Vergi Dairesi:</label>
										<input
											type="text"
											value={this.props.tax_office}
											name="tax_office"
										/>
									</div>
									<div className="input-with-label">
										<label>Vergi No:</label>
										<input
											type="text"
											value={this.props.tax_number}
											name="tax_number"
										/>
									</div>
									<div className="input-with-label">
										<label>Adres:</label>
										<textarea
											onChange={this.OnChange}
											onPaste={this.OnChange}
											defaultValue={this.props.address}
											name="address"
											style={{
												height: `${this.state.lineCount * 17}px`,
											}}
										/>
									</div>
								</React.Fragment>
							)}
							<button
								className="btn btn-profile"
								onClick={this.TogglePopup}
								value="change-address"
							>
								FATURA BİLGİLERİNİ GÜNCELLE
							</button>
						</form>
					</PopUp>
				)}
			</SettingsBox>
		);
	}
}

export default ProfileSettings;
